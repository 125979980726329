.sticky-navbar {
  position: sticky;
  top: 0;
  z-index: 1000; 
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); 
}

.navbar-custom {
  background-color: #ffffff; 
  border-bottom: 1px solid #e0e0e0; 
  padding: 1rem 2rem; 
}

.brand {
  font-size: 1.8rem; 
  font-weight: bold; 
  color: #007bff; 
  transition: color 0.3s; 
}

.brand:hover {
  color: #0056b3; 
}

.nav-link {
  font-size: 1.2rem; 
  color: #555; 
  margin: 0 1rem; 
  transition: color 0.3s; 
}

.nav-link:hover {
  color: #007bff; 
}

.nav-link.active {
  color: #007bff; 
}

@media (max-width: 768px) {
  .navbar-custom {
      padding: 0.5rem 1rem; 
  }

  .brand {
      font-size: 1.4rem; 
  }

  .nav-link {
      font-size: 1rem; 
      margin: 0.5rem 0; /* Stack links vertically on mobile */
      display: block; /* Make links take the full width */
  }

  .navbar-collapse {
      text-align: center; /* Center the links */
  }
}
