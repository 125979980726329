/* src/styles/home.css */

body {
    font-family: "Playwrite DE Grund", cursive;
}

.hero-section {
    background-color: #f8f9fa; /* Light background for contrast */
    padding: 80px 15px; /* Increased padding for larger screens */
}

h1 {
    color: #007bff;
    font-weight: bold;
}

.hero-title {
    font-size: 3rem; /* Larger title for emphasis */
    color: #007bff; /* Dark color for contrast */
    font-weight: bold;
    margin-bottom: 20px;
}

.hero-description {
    font-size: 1.5rem; /* Larger readable text */
    color: #666; /* Softer color for description */
    max-width: 600px; /* Limit width for better readability */
    margin: 0 auto; /* Center the description */
}

/* General card styles */
.card {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center items horizontally */
    padding: 20px; /* Add padding for consistency */
    background-color: #fff; /* White background */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    transition: transform 0.3s; /* Smooth scaling effect on hover */
    height: 100%; /* Make sure all cards are the same height */
}

.card:hover {
    transform: scale(1.05); /* Scale effect on hover */
}

/* Service cards */
.service-card {
    background-color: #ffffff; /* White background for cards */
    padding: 40px; /* Increased padding for larger screens */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Soft shadow */
    transition: transform 0.3s, box-shadow 0.3s; /* Smooth hover effect */
    margin-bottom: 30px; /* Space below each card */
    text-align: center; /* Center align text */
}

.icon-img {
    width: 80px; /* Adjusted icon width for larger screens */
    height: auto; /* Maintain aspect ratio */
}

.service-card:hover {
    transform: translateY(-5px); /* Lift effect on hover */
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.15); /* Darker shadow on hover */
}

.services-title {
    font-size: 2.5rem; /* Larger section title */
    color: #007bff; /* Bootstrap primary color */
    margin-bottom: 40px; /* Space below title */
}

/* Tooltips */
.tooltip {
    font-size: 0.9rem; /* Smaller font size */
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .hero-title {
        font-size: 2rem; /* Adjust title size on mobile */
    }
    .hero-description {
        font-size: 1rem; /* Adjust description size */
    }
}

/* Specific styles for Why Us and How We Operate */
.why-us, #how-we-operate {
    background-color: #f9f9f9; /* Light background for contrast */
    padding: 40px 0; /* Top and bottom padding */
}

.why-us-title, .hwo-title {
    text-align: center;
    margin-bottom: 40px;
}

.why-us-item, .process-step {
    padding: 20px;
    background-color: #fff; /* White background for items */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    text-align: center;
}

.why-us-item-icon, .step-number {
    width: 80px; /* Set desired icon width */
    height: auto; /* Maintain aspect ratio */
    margin-bottom: 15px; /* Space between icon and title */
}

.process-step {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
    padding: 20px; /* Padding inside each step */
    background-color: #fff; /* White background for steps */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    flex: 1; /* Allow steps to grow equally */
    margin: 10px; /* Space between steps */
    height: auto; /* Allow auto height */
}

.step-content {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center align items */
    width: 100%; /* Full width */
}

.step-number {
    font-size: 2.5rem; /* Larger size for step number */
    font-weight: bold;
    color: #007bff; /* Primary color */
    margin-bottom: 10px; /* Space below the number */
    line-height: 1; /* Reduce line height for better vertical alignment */
    display: flex; /* Flexbox for centering */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    min-height: 80px; /* Fixed height to align numbers */
}

h2 {
    margin-top: 10px; /* Add some margin for spacing */
    font-weight: bold;
}

p {
    margin-bottom: 0; /* Remove bottom margin for better spacing */
    text-align: center; /* Center align the paragraph */
}

.icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px; /* Adjust height as needed */
}

.why-us-item-icon {
    max-width: 100%; /* Ensure the icon scales responsively */
    height: auto; /* Maintain aspect ratio */
}

.contact-form {
    background-color: #f8f9fa; /* Light background for the form */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.contact-title h1 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 20px;
}

h3 {
    margin-bottom: 10px;
    font-weight: 500;
}

p {
    margin-bottom: 10px;
}

/* Additional responsiveness */
@media (max-width: 768px) {
    .contact-title h1 {
        font-size: 2rem;
    }

    .text-center {
        margin-top: 25px;
    }
}

.iframe-container {
    position: relative;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
    overflow: hidden;
}

.iframe-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none; /* Remove default border */
}


ul {
    padding-left: 0; /* Remove default padding */
}

li {
    margin-bottom: 20px; /* Space between list items */
}

h4 {
    margin-bottom: 5px; /* Space between title and description */
}

/* Add this in your CSS file */
.slick-slide {
    padding: 10px; /* Add some padding around the slides */
}

.slick-prev:before,
.slick-next:before {
    color: #007bff; /* Change arrow color */
}
