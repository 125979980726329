.footer {
    background-color: #f8f9fa;
    border-top: 1px solid #e9ecef;
    padding: 2rem 0; /* Add some vertical padding */
    text-align: center; /* Center-align text in the footer */
}

.footer-brand h3 {
    color: #007bff;
}

.footer-links h5,
.footer-contact h5 {
    margin-bottom: 1rem;
}

.footer-links ul {
    list-style: none;
    padding: 0;
}

.footer-links ul li {
    margin: 0.5rem 0;
}

.footer-links ul li a {
    text-decoration: none;
    color: #343a40;
}

.footer-links ul li a:hover {
    text-decoration: underline;
}

.footer-contact p {
    margin: 0.5rem 0;
}

.social-icons {
    display: flex;
    justify-content: center; /* Center the icons */
    gap: 1rem;
    margin-top: 0.5rem;
}

.social-icons a {
    color: #007bff;
    font-size: 1.5rem;
    transition: color 0.3s;
}

.social-icons a:hover {
    color: #0056b3;
}

/* Optional: Add media queries for smaller screens */
@media (max-width: 576px) {
    .footer-links,
    .footer-contact {
        margin-bottom: 1rem; /* Add spacing between columns on small screens */
    }
}
